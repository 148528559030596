import React from 'react';
// import { useTranslation } from 'react-i18next';
import RelaxionLabels from 'assets/relaxion_labels.png';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';
import Spacer from 'components/spacer/Spacer';
import { useAddonsLogic } from 'subscription/workbook/Addons.hook';
import {
  convertToProduct,
  getAddonsPlansConfig,
  getWebAudioProductCode,
} from 'bootstrap/data/product/Product.utils';
import { withDefaultProduct } from 'subscription/withDefaultProduct/withDefaultProduct';
import styles from './styles.module.scss';

const DISCOUNT = 50;

export const QuarterUpsell: React.FC = withDefaultProduct(() => {
  const webAudioProductCode = getWebAudioProductCode();
  const addonsPlans = getAddonsPlansConfig();

  // @ts-ignore
  const webAudioProduct = addonsPlans?.items?.[webAudioProductCode];
  const convertedProduct = convertToProduct(webAudioProduct);

  const { isLoading, onSkipClickHandler, onAddonBuyClickHandler } =
    useAddonsLogic({ planCode: webAudioProductCode });

  return (
    <div className={`${styles.container} maxWidth`}>
      <div className={styles.content}>
        <div className={styles.title}>
          Unlock deep relaxation off-screen audio bundle
        </div>
        <div className={styles.description}>
          Recommended for new users like you to achieve better results. Curated
          collection of audio meditations, along with calming Green and Pink
          noise options.
        </div>
      </div>
      <img
        src={RelaxionLabels}
        className={styles.image}
        alt="relaxion labels"
      />
      <Spacer />
      <div className={styles.totalWrapper}>
        <h2 className={styles.total}>Total today</h2>
        <div className={styles.priceWrapper}>
          <p className={styles.discount} aria-label="Discount">
            -{DISCOUNT}%
          </p>
          <p className={styles.oldPrice} aria-label="Original price">
            {convertedProduct.getPriceWithoutDiscount(DISCOUNT)}
          </p>
          <p className={styles.newPrice} aria-label="Discounted price">
            {convertedProduct.getFormattedPrice()}
          </p>
        </div>
      </div>
      <StickyContainer className={styles.stickyContainer}>
        <button className={styles.grayButton} onClick={onSkipClickHandler}>
          Skip
        </button>
        <LoadingButton
          isLoading={isLoading}
          label="Add to my plan"
          className={`${styles.button} ${styles.greenButton}`}
          onClick={onAddonBuyClickHandler}
        />
      </StickyContainer>
      <div className={styles.note}>
        On click, your account will be charged immediately for the add-on
        services or the products selected.
      </div>
    </div>
  );
});
