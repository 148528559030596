import { getString } from 'firebase/remote-config';
import { remoteConfig } from 'analytics/Firebase';
import { getQueryVariable } from 'utils';
import { Product } from './Product';
import { Currency, ServerProduct, Products } from './Product.types';
import { PresaleOfferConfig } from 'subscription/presale/Presale.types';
import { PlanSelectType } from 'subscription/singleproduct/components/plansection/PlanSection.constants';

type PromoType = 'promo' | 'default';
type TrialType = 'standard_trial' | 'extended_trial';

export function convertToProduct(
  serverProduct: ServerProduct,
  description?: string,
): Product {
  return new Product(
    serverProduct.id,
    serverProduct.code,
    serverProduct.name,
    description || serverProduct.description,
    serverProduct.interval_unit,
    serverProduct.interval_length,
    serverProduct.trial_unit,
    serverProduct.trial_length,
    serverProduct.ramp_intervals,
    serverProduct.currencies,
    serverProduct.discount,
    serverProduct.full_price,
  );
}

export const defaultProductCode = getString(
  remoteConfig,
  'default_product_code',
);

export const getCurrencyByCurrencyCode = (
  currencies: Currency[],
  currencyCode?: string,
): Currency => {
  const defaultCurrencyCode = 'USD';

  const matchingCurrency = currencies.find(
    ({ currency }) => currency === currencyCode,
  );

  if (matchingCurrency) {
    return matchingCurrency;
  }

  const defaultCurrency = currencies.find(
    ({ currency }) => currency === defaultCurrencyCode,
  );

  if (defaultCurrency) {
    return defaultCurrency;
  }

  throw new Error(
    `Currency with code '${currencyCode}' not found in the provided currency list.`,
  );
};

export function getProductPrefix() {
  return getQueryVariable('pr');
}

export function getPresaleConfigPrefix() {
  return getQueryVariable('ps_offer_config');
}

export const getDefaultProductCode = (
  type: 'standard' | 'extended',
): string => {
  const productPrefix = getProductPrefix() || 'default';
  const productConfigName = `${productPrefix}_${type}_product_code`;

  return (
    getString(remoteConfig, productConfigName) ||
    getString(remoteConfig, `default_${type}_product_code`)
  );
};

export const getSkipTrialProductCode = (type?: PlanSelectType): string => {
  return getString(
    remoteConfig,
    type === PlanSelectType.MONTH
      ? 'promo_skip_trial_no_trial_product_code_plan_select'
      : 'promo_skip_trial_no_trial_product_code',
  );
};

export const getMonthDirectProductCode = (): string => {
  return getString(remoteConfig, 'plan_select_month_direct_product_code');
};

export const getMonthUpsellStepOneProductCode = (): string => {
  return getString(remoteConfig, 'plan_select_month_upsell_step1_product_code');
};

export const getMonthUpsellStepTwoProductCode = (): string => {
  return getString(remoteConfig, 'plan_select_month_upsell_step2_product_code');
};

export const getQuarterDirectProductCode = (): string => {
  return getString(remoteConfig, 'plan_select_quarter_direct_product_code');
};

export const getWeekDirectProductCode = (): string => {
  return getString(remoteConfig, 'plan_select_week_direct_product_code');
};

export const getWeekUpsellStepOneProductCode = (): string => {
  return getString(remoteConfig, 'plan_select_week_upsell_step1_product_code');
};

export const getWeekUpsellStepTwoProductCode = (): string => {
  return getString(remoteConfig, 'plan_select_week_upsell_step2_product_code');
};

export const getPresaleOfferConfig = (
  defaultPresaleOfferConfig: PresaleOfferConfig[],
): PresaleOfferConfig[] => {
  const configPrefix = getPresaleConfigPrefix();
  const configName = `${configPrefix}_presale_offer_config`;
  const config = getString(remoteConfig, configName);

  if (!config) return defaultPresaleOfferConfig;

  return JSON.parse(config);
};

export const getAddonsPlansConfig = (): Products => {
  const configName = 'items';
  const config = getString(remoteConfig, configName);

  return JSON.parse(config);
};

export const getWorkbookProductCode = (): string => {
  return getString(remoteConfig, 'workbook_addon_product_code');
};

export const getWorkbookWithDiscountProductCode = (): string => {
  return getString(remoteConfig, 'workbook_addon_with_discount_product_code');
};

export const getWebAudioProductCode = (): string => {
  return getString(remoteConfig, 'web_audio_product_code');
};

export function convertToProductMap(
  serverProducts: Record<string, ServerProduct>,
): Map<string, Product> {
  return Object.entries(serverProducts).reduce<Map<string, Product>>(
    (result, current) => {
      const [code, product] = current;
      if (
        (Array.isArray(product.currencies) && product.currencies.length) ||
        product.ramp_intervals.length
      ) {
        result.set(code, convertToProduct(product));
      }
      return result;
    },
    new Map<string, Product>(),
  );
}

export function convertToProducts(products: Map<string, Product>): Products {
  const constants: Record<
    PromoType,
    Record<TrialType, Record<string, string>>
  > = {
    promo: {
      standard_trial: {
        code: getString(remoteConfig, 'promo_standard_product_code'),
        description: 'Free Trial (7 days)',
      },
      extended_trial: {
        code: getString(remoteConfig, 'promo_extended_product_code'),
        description: 'Extended Free Trial (14 days)',
      },
    },
    default: {
      standard_trial: {
        code: getDefaultProductCode('standard'),
        description: 'Free Trial (7 days)',
      },
      extended_trial: {
        code: getDefaultProductCode('extended'),
        description: 'Extended Free Trial (14 days)',
      },
    },
  };

  const getProduct = (promoType: PromoType, trialType: TrialType): Product => {
    const constant = constants[promoType][trialType];
    const currentProduct = products?.get(constant.code);
    if (currentProduct) {
      const product = Object.create(currentProduct);
      constant.description && (product.description = constant.description);
      return product;
    }
    throw new Error('Unable to get product info');
  };

  return {
    promo: {
      standard_trial: getProduct('promo', 'standard_trial'),
      extended_trial: getProduct('promo', 'extended_trial'),
    },
    default: {
      standard_trial: getProduct('default', 'standard_trial'),
      extended_trial: getProduct('default', 'extended_trial'),
    },
  };
}
