import React from 'react';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import Spacer from 'components/spacer/Spacer';
import { EbookOfferBox } from './components/ebookoffer/EbookOfferBox';
import { useAddonsLogic } from './Addons.hook';
import { ErrorPopup } from 'components/errorpopup/ErrorPopup';
import styles from './styles.module.scss';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';

export const Workbook = () => {
  const {
    isLoading,
    error,
    setError,
    onSkipClickHandler,
    onAddonBuyClickHandler,
  } = useAddonsLogic({});

  return (
    <>
      <ErrorPopup
        visible={!!error}
        error={error}
        onClose={() => setError('')}
      />
      <div className={`${styles.container} maxWidth`}>
        <h4>
          Get extra help with <br />
          our <span className={styles.accent}>Premium Workbook</span>
        </h4>
        <div className={styles.description}>
          <span>
            Recommended for new users like you to help improve your sleep and
            reduce anxiety.
          </span>
          <span>
            With Workbook you are 29% more likely to achieve better results in
            first 3 weeks.
          </span>
        </div>
        <EbookOfferBox
          items={[
            'Extra help',
            'Improve your sleep routine',
            'Foods and Activities recommendations',
          ]}
          showPrice={true}
        />
        <Spacer />
        <StickyContainer className={styles.stickyContainer}>
          <button className={styles.grayButton} onClick={onSkipClickHandler}>
            Skip
          </button>
          <LoadingButton
            isLoading={isLoading}
            label="Buy now"
            className={styles.button}
            onClick={onAddonBuyClickHandler}
          />
        </StickyContainer>
        <div className={styles.note}>
          On click, your account will be charged one payment
          <br /> of $9.99 for your Premium Workbook
        </div>
      </div>
    </>
  );
};
