import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import { FeatureTypes } from 'featureTypes';

export type Features = {
  isPresaleFlow: boolean;
  isLocalCurrencyEnabled: boolean;
  isSleepActivityLoaderV2: boolean;
  isFeaturedInFlow: boolean;
  isWelcomeLoaderFlow: boolean;
  isPreplanFlow: boolean;
  isAnimationSpeedFlow: boolean;
  isRelateScaleQuestion: boolean;
  isPersonalizationLoaderEstimateFlow: boolean;
  isDefaultOnboarding: boolean;
  isCongratsV2: boolean;
  isIgnoreSkipTrialFlow: boolean;
  isSkipTrialWithoutRefund: boolean;
  isSkipTrialV3: boolean;
  isLayoutCardPaymentForm: boolean;
  isPreselectProductOffer: boolean;
  isFacebookReviews: boolean;
  isGadTest: boolean;
  isFaq: boolean;
  isFreeVsPaid: boolean;
  isBlitzV2Flow: boolean;
  isPresalePulsingButtonFlow: boolean;
  isTrialExplainerWithDate: boolean;
  isTrialExplainerWithHighlight: boolean;
  isHorizontalTrialExplainer: boolean;
  isFlexibleOmitViewEventsFlow: boolean;
  isMeqResultV2Flow: boolean;
  isMeqResultAnimalFlow: boolean;
  isWorkbookUpsellFlow: boolean;
  isPaymentPreventBack: boolean;
  isEducationV1Quiz: boolean;
  isEducationV2Quiz: boolean;
  isEducationV3Quiz: boolean;
  isInsomniaV1Quiz: boolean;
  isInsomniaV2Quiz: boolean;
  isAdhdTest: boolean;
  upsellFlow: boolean;
};

type FeatureFlagKey = `${FeatureTypes}`;

export const useFeatures = (): Features => {
  return {
    isPresaleFlow: useFeatureIsOn(FeatureTypes.PRESALE_OFFER as FeatureFlagKey),
    isLocalCurrencyEnabled: useFeatureIsOn(
      FeatureTypes.LOCAL_CURRENCY as FeatureFlagKey,
    ),
    isSleepActivityLoaderV2: useFeatureIsOn(
      FeatureTypes.SLEEP_ACTIVITY_LOADER_V2 as FeatureFlagKey,
    ),
    isFeaturedInFlow: useFeatureIsOn(
      FeatureTypes.FEATURED_IN as FeatureFlagKey,
    ),
    isWelcomeLoaderFlow: useFeatureIsOn(
      FeatureTypes.WELCOME_LOADER as FeatureFlagKey,
    ),
    isPreplanFlow: useFeatureIsOn(FeatureTypes.PREPLAN as FeatureFlagKey),
    isAnimationSpeedFlow: useFeatureIsOn(
      FeatureTypes.ANIMATION_SPEED_INCREASE as FeatureFlagKey,
    ),
    isRelateScaleQuestion: useFeatureIsOn(
      FeatureTypes.RELATE_SCALE_QUESTION as FeatureFlagKey,
    ),
    isPersonalizationLoaderEstimateFlow: useFeatureIsOn(
      FeatureTypes.PERSONALIZATION_LOADER as FeatureFlagKey,
    ),
    isDefaultOnboarding:
      useFeatureValue(FeatureTypes.SPECIAL_FLOW, '') === 'onboarding',
    isCongratsV2: useFeatureValue(FeatureTypes.CONGRATS, '') === 'congrats_v2',
    isIgnoreSkipTrialFlow: !Boolean(
      useFeatureValue(FeatureTypes.SKIP_TRIAL, ''),
    ),
    isSkipTrialWithoutRefund:
      useFeatureValue(FeatureTypes.SKIP_TRIAL, '') === 'nr',
    isSkipTrialV3: useFeatureValue(FeatureTypes.SKIP_TRIAL, '') === 'v3',
    isLayoutCardPaymentForm:
      useFeatureValue(FeatureTypes.PAYMENT_FORM, '') === 'cardlyt',
    isPreselectProductOffer: !!useFeatureValue(FeatureTypes.PRODUCT, ''),
    isFacebookReviews:
      useFeatureValue(FeatureTypes.FACEBOOK_REVIEWS, '') === 'fb',
    isGadTest: Boolean(useFeatureValue(FeatureTypes.GAD, '')),
    isFaq: Boolean(useFeatureValue(FeatureTypes.FAQ, '')),
    isFreeVsPaid: Boolean(useFeatureValue(FeatureTypes.FREE_PAID, '')),
    isBlitzV2Flow: useFeatureValue(FeatureTypes.BLITZ, '') === 'anx',
    isPresalePulsingButtonFlow:
      useFeatureValue(FeatureTypes.PRICE_BUTTON, '') === 'animated',
    isTrialExplainerWithDate:
      useFeatureValue(FeatureTypes.TRIAL_EXPLAINER, '') === 'dt',
    isTrialExplainerWithHighlight:
      useFeatureValue(FeatureTypes.TRIAL_EXPLAINER, '') === 'highlight',
    isHorizontalTrialExplainer:
      useFeatureValue(FeatureTypes.TRIAL_EXPLAINER, '') === 'horizontal',
    isFlexibleOmitViewEventsFlow:
      useFeatureValue(FeatureTypes.EVENT_POLICIES, '') === 'fov',
    isMeqResultV2Flow: useFeatureValue(FeatureTypes.MEQ_RESULTS, '') === 'ext',
    isMeqResultAnimalFlow:
      useFeatureValue(FeatureTypes.MEQ_RESULTS, '') === 'anim',
    isWorkbookUpsellFlow: useFeatureValue(FeatureTypes.WORKBOOK, '') === 'ups',
    isPaymentPreventBack:
      useFeatureValue(FeatureTypes.PREVENT_BACK, '') === 'payment',
    isEducationV1Quiz: useFeatureValue(FeatureTypes.EDU, '') === 'v1',
    isEducationV2Quiz: useFeatureValue(FeatureTypes.EDU, '') === 'v2',
    isEducationV3Quiz: useFeatureValue(FeatureTypes.EDU, '') === 'v3',
    isInsomniaV1Quiz: useFeatureValue(FeatureTypes.ISI, '') === 'v1',
    isInsomniaV2Quiz: useFeatureValue(FeatureTypes.ISI, '') === 'v2',
    isAdhdTest: useFeatureValue(FeatureTypes.FLOW, '') === 'adhd_10',
    upsellFlow: Boolean(useFeatureValue(FeatureTypes.UPSELL_FLOW, '')),
  };
};

export const isQuickPurchaseFlow = (): boolean =>
  window.location.pathname.split('/').includes('quick-purchase');
export const isInviteFlow = (): boolean =>
  window.location.pathname.split('/').includes('invite');
export const isGiftFlow = (): boolean =>
  window.location.pathname.split('/').includes('gift');
export const isRedeemFlow = (): boolean =>
  window.location.pathname.split('/').includes('redeem');
export const isSubscriptionCancelFlow = (): boolean =>
  window.location.pathname.split('/').includes('subscription-cancel');
export const isShortWebCancelFlow = (): boolean =>
  useFeatureValue<string>('type', '') === 'short';
