import { UserActionTypes } from './userActions.types';
import { Product } from 'bootstrap/data/product/Product';

export const SET_BOUGHT_PRODUCT = 'SET_BOUGHT_PRODUCT';

export function setProductBought(boughtProduct: Product): UserActionTypes {
  return {
    type: SET_BOUGHT_PRODUCT,
    payload: {
      boughtProduct,
    },
  };
}
