import React from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from 'bootstrap/data/product/Product';
import styles from './styles.module.scss';

type Props = {
  upsellProduct?: Product;
  boughtProduct?: Product;
};

export const GraphsContainer = ({ upsellProduct, boughtProduct }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.graphsContainer}>
      <div className={`${styles.graph} ${styles.small}`}>
        <div className={styles.trialGraph}>
          <span className={styles.percent}>
            {boughtProduct?.getTreeMonthsFormattedPrice()}
          </span>
        </div>
        <span className={styles.caption}>
          {upsellProduct?.getTerm(t, true)?.toLowerCase()} with your plan now
        </span>
      </div>

      <div className={styles.graph}>
        <div className={styles.skipTrialGraph}>
          <span className={styles.percent}>
            {upsellProduct?.getFormattedPrice()}
          </span>
        </div>
        <span className={styles.caption}>
          {upsellProduct?.getTerm(t, true)?.toLowerCase()} with limited offer
        </span>
      </div>
    </div>
  );
};
