import { Event } from 'analytics/Event';
import { Events } from 'analytics/Events';

export class SubscriptionCancelScreenOpened extends Event {
  constructor(screenName: string) {
    super(Events.SubscriptionCancelScreenOpened, { screenName });
  }
}

export class ContinueSubscriptionCancel extends Event {
  constructor() {
    super(Events.ContinueSubscriptionCancel);
  }
}
export class ContinueSubscriptionCancelReason extends Event {
  constructor(reply: string) {
    super(Events.ContinueSubscriptionCancelReason, { reply });
  }
}

export class ContinueSubscriptionCancelHowLikely extends Event {
  constructor(reply: string) {
    super(Events.ContinueSubscriptionCancelHowLikely, { reply });
  }
}

export class SubscriptionProlongSuccess extends Event {
  constructor(duration: number) {
    super(Events.SubscriptionProlongSuccess, { duration });
  }
}

export class BuyAddonPlan extends Event {
  constructor(productCode: string) {
    super(Events.BuyAddonPlan, { productCode });
  }
}

export class SubscriptionSwitchSuccess extends Event {
  constructor(planCode: string) {
    super(Events.SubscriptionSwitchSuccess, { planCode });
  }
}
