import { useRecurly } from '@recurly/react-recurly';
import { ApplePayInstance } from '@recurly/recurly-js';
import { appAnalytics } from 'analytics';
import { ApplePayClicked, PayPalClicked } from 'analytics/AllEvents';
import { Product } from 'bootstrap/data/product/Product';
import { buyProduct } from 'bootstrap/giftpurchase/actions/buyProduct';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import {
  createSubscription,
  createSubscriptionByIdx,
} from 'bootstrap/net/subscription';
import { setTrialOfferShown } from 'bootstrap/user/actions';
import { push } from 'connected-react-router';
import { useEffect, useMemo, useState } from 'react';
import { getQueryVariable, pushWithSearchQuery } from 'utils';
import { SuccessPaymentProps } from 'subscription/recurly/Recurly.types';
import {
  getApplePayLabel,
  getApplePayPrice,
} from 'subscription/recurly/components/paymentpicker/PaymentsPicker.utils';
import { getErrorMessage } from 'bootstrap/net/commonnet';
import {
  BRAINTREE_CLIENT_AUTHORIZATION,
  PAYMENT_CANCEL,
  PAYMENT_ERROR,
  PAYMENT_SUCCESS,
} from 'subscription/recurly/components/paymentpicker/PaymentsPicker.constants';
import { setSubscriptionToken } from 'bootstrap/user/actions/setUserInfo';
import { withRecaptcha } from 'subscription/recurly/components/withRecaptcha/withRecaptcha';
import { PaymentMethods } from 'subscription/components/Payments/Payments.types';
import { isApplePayAvailable } from 'subscription/components/Payments/Payments.utlls';
import { isGiftFlow, useFeatures } from 'useFeatures';

type Props = {
  product: Product;
  darkTheme?: boolean;
  onSuccess: (params: SuccessPaymentProps) => void;
  onError: (message: string) => void;
};

export const usePaymentsLogic = ({ product, onSuccess, onError }: Props) => {
  const dispatch = useAppDispatch();
  const { isWorkbookUpsellFlow, isAdhdTest, upsellFlow } = useFeatures();

  const { currency, countryCode } = useAppSelector((state) => state.user);

  const applePayLabel = useMemo(() => getApplePayLabel(product), [product]);
  const applePayPrice = useMemo(
    () => getApplePayPrice(product),
    [product, isGiftFlow],
  );

  const recurly = useRecurly();

  const payPalInstance = recurly.PayPal({
    braintree: {
      clientAuthorization: BRAINTREE_CLIENT_AUTHORIZATION,
    },
  });

  const applePayInstance = recurly.ApplePay({
    // @ts-ignore
    countryCode: countryCode || 'US',
    country: countryCode || 'US',
    currency: currency || 'USD',
    label: applePayLabel,
    total: applePayPrice,
    // @ts-ignore
    braintree: {
      clientAuthorization: BRAINTREE_CLIENT_AUTHORIZATION,
    },
  });

  const [errorMessage, setError] = useState<string>('');
  const [isPaypalLoading, setPaypalLoading] = useState<boolean>(false);
  const [isApplePayCanceled, setApplePayCanceled] = useState<boolean>(false);
  const [isPaypalReady, setPaypalReady] = useState<boolean>(false);

  const [activeApplePayInstance, setActiveApplePayInstance] =
    useState<ApplePayInstance>(applePayInstance);

  useEffect(() => {
    payPalInstance.on('ready', () => {
      setPaypalReady(true);
    });
  }, []);

  useEffect(() => {
    setActiveApplePayInstance(applePayInstance);
  }, [product]);

  useEffect(() => {
    if (isApplePayCanceled) {
      setActiveApplePayInstance(applePayInstance);
    }
    setApplePayCanceled(false);
  }, [isApplePayCanceled]);

  const handleToken = withRecaptcha(
    // @ts-ignore
    async (
      recaptchaToken: string,
      token: { id: string },
      paymentMethod: PaymentMethods,
    ) => {
      dispatch(setSubscriptionToken(token.id));

      if (isGiftFlow()) {
        dispatch(
          buyProduct({
            product: product as any,
            token: token.id,
            recaptchaToken,
          }),
        )
          .then(() => {
            setPaypalLoading(false);
            dispatch(push('/gift/done'));
          })
          .catch((error) => {
            setPaypalLoading(false);
            setError(error);
          });
      } else {
        subscribe(token.id, product, paymentMethod, recaptchaToken);
      }
    },
    () => {
      // @ts-ignore
    },
    setError,
  );

  const setErrorMessage = (error: string) => setError(error);

  const subscribe = async (
    token: string,
    customProduct: Product,
    paymentMethod: PaymentMethods,
    recaptchaToken: string,
  ) => {
    try {
      const idx = getQueryVariable('idx');
      let response;

      if (idx) {
        response = await createSubscriptionByIdx({
          product: customProduct,
          token,
          bundle: getQueryVariable('bundle'),
          idx,
          paymentMethod,
          recaptchaToken,
        });
      } else {
        response = await createSubscription({
          product: customProduct,
          token,
          currency,
          paymentMethod,
          recaptchaToken,
        });
      }

      if (isWorkbookUpsellFlow || isAdhdTest || upsellFlow) {
        dispatch(pushWithSearchQuery('/workbook'));
      } else {
        dispatch(setTrialOfferShown(true));
      }

      setPaypalLoading(false);

      onSuccess({
        orderId: response.purchase.order_id,
        productBought: customProduct,
      });
    } catch (error: any) {
      setPaypalLoading(false);

      if (error?.code === 'USER_NOT_FOUND') {
        dispatch(pushWithSearchQuery('/quick-purchase/login', ['idx']));
      } else {
        setError(getErrorMessage(error));
      }

      onError(getErrorMessage(error));
    }
  };

  const initializePayment = (paymentMethod: PaymentMethods, instance: any) => {
    const handleError = (error: { message: string }) => {
      setPaypalLoading(false);

      const message = error.message || 'Unknown error, please try again';
      onError(message);
      setError(message);
    };

    const handleCancel = () => {
      setPaypalLoading(false);
      setApplePayCanceled(true);
    };

    setError('');

    instance.on(PAYMENT_SUCCESS, (token: any) =>
      // @ts-ignore
      handleToken(token, paymentMethod),
    );
    instance.on(PAYMENT_ERROR, handleError);
    instance.on(PAYMENT_CANCEL, handleCancel);

    if (paymentMethod === PaymentMethods.ApplePay) {
      instance.begin();
    } else {
      instance.start();
    }
  };

  const handlePaypalChange = () => {
    setPaypalLoading(true);
    appAnalytics.trackEvent(new PayPalClicked());
    initializePayment(PaymentMethods.PayPal, payPalInstance);
  };

  const handleApplePayChange = () => {
    appAnalytics.trackEvent(new ApplePayClicked());
    initializePayment(PaymentMethods.ApplePay, activeApplePayInstance);
  };

  const handlePaymentMethodChange = (option: PaymentMethods) => {
    switch (option) {
      case PaymentMethods.PayPal: {
        handlePaypalChange();
        break;
      }
      case PaymentMethods.ApplePay: {
        handleApplePayChange();
        break;
      }
      default:
        break;
    }
  };

  const pickerOptions = useMemo(() => {
    const paymentMethods = [PaymentMethods.Card, PaymentMethods.PayPal];

    if (isApplePayAvailable()) {
      paymentMethods.push(PaymentMethods.ApplePay);
    }

    return paymentMethods;
  }, []);

  return {
    pickerOptions,
    errorMessage,
    isPaypalLoading,
    isPaypalReady,
    setErrorMessage,
    handlePaymentMethodChange,
  };
};
