import React from 'react';
import Ebook from 'assets/onboarding/ebook_v2.png';
import { ReactComponent as Checked } from 'assets/stepper/check.svg';
import styles from './styles.module.scss';

interface EbookOfferBoxProps {
  items: string[];
  showPrice?: boolean;
}

export const EbookOfferBox: React.FC<EbookOfferBoxProps> = ({
  items,
  showPrice,
}) => {
  return (
    <div className={styles.container}>
      <img src={Ebook} alt="" />
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>One-time price</div>
          {showPrice && <div className={styles.price}>$9.99</div>}
        </div>
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <Checked className={styles.check} /> {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
