import React, { useEffect } from 'react';
import Modal from 'components/modal/Modal';
import { ReactComponent as Discount } from 'assets/discount.svg';
import { ViewSkipWeekMonthUpsellPopup } from 'analytics/AllEvents';
import { appAnalytics } from 'analytics';
import { Product } from 'bootstrap/data/product/Product';
import styles from './styles.module.scss';

type Props = {
  discount: number;
  product?: Product;
  handleContinueClick: () => void;
};

export const DiscountPopup = ({
  product,
  discount,
  handleContinueClick,
}: Props) => {
  useEffect(() => {
    appAnalytics.trackEvent(new ViewSkipWeekMonthUpsellPopup());
  }, []);

  return (
    <Modal visible={true} className={styles.modal}>
      <div className={styles.contentWrapper}>
        <div className={styles.discountBackground}>
          <Discount />
        </div>
        <div className={styles.offer}>
          <div className={styles.title}>Are you sure?</div>
          <p className={styles.description}>
            Building lasting habits takes time, but with our{' '}
            {product?.getTerm()} plan, you’ll have the ongoing support you need
            to create real, positive change. As a thank-you for committing to
            your wellness, we’ll take an{' '}
            <b>extra {discount}% off your total.</b>{' '}
          </p>
        </div>
        <button onClick={handleContinueClick}>
          Get My Extra {discount}% off
        </button>
      </div>
    </Modal>
  );
};
