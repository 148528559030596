import { TFunction } from 'i18next';
import { formatPrice } from 'utils';
import { getCurrentLanguage } from 'utils/utils';
import { getCurrencyByCurrencyCode } from './Product.utils';
import { Currency, RampInterval } from './Product.types';

export class Product {
  id: string;
  code: string;
  name: string;
  description: string;
  intervalUnit: 'months' | 'days';
  intervalLength: number;
  trialUnit: 'months' | 'days';
  trialLength: number;
  amount: number;
  currency: string;
  setupFee?: number;
  currencies: Currency[];
  rampIntervals: RampInterval[];
  discount?: {
    value: number;
    fullPrice: number;
  };

  constructor(
    id: string,
    code: string,
    name: string,
    description: string,
    intervalUnit: 'months' | 'days',
    intervalLength: number,
    trialUnit: 'months' | 'days',
    trialLength: number,
    rampIntervals: RampInterval[],
    currencies: Currency[],
    discount?: number,
    fullPrice?: number,
  ) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.description = description;
    this.intervalUnit = intervalUnit;
    this.intervalLength = intervalLength;
    this.trialUnit = trialUnit;
    this.trialLength = trialLength;
    this.rampIntervals = rampIntervals.sort(
      (a, b) => a.starting_billing_cycle - b.starting_billing_cycle,
    );

    this.currencies = rampIntervals.length
      ? rampIntervals[0].currencies
      : currencies;

    const {
      currency,
      unit_amount: unitAmount,
      setup_fee: setupFee,
    } = this.getDefaultCurrency();

    this.currency = currency;
    this.amount = unitAmount;

    this.setupFee = setupFee === 0.0 || null ? 0 : setupFee;

    if (discount && fullPrice) {
      this.discount = {
        value: discount,
        fullPrice: fullPrice,
      };
    }
  }

  getDefaultCurrency(): Currency {
    return this.rampIntervals.length
      ? getCurrencyByCurrencyCode(this.rampIntervals[0].currencies, 'USD')
      : getCurrencyByCurrencyCode(this.currencies, 'USD');
  }

  getFormattedPrice(currencyCode?: string): string {
    if (!currencyCode || !this.currencies) {
      return formatPrice(this.amount, this.currency);
    }
    const { currency, unit_amount } = getCurrencyByCurrencyCode(
      this.currencies,
      currencyCode,
    );
    return formatPrice(unit_amount, currency);
  }

  getTreeMonthsFormattedPrice(currencyCode?: string): string {
    if (!currencyCode || !this.currencies) {
      return formatPrice(this.amount * 3, this.currency);
    }
    const { currency, unit_amount } = getCurrencyByCurrencyCode(
      this.currencies,
      currencyCode,
    );
    return formatPrice(unit_amount * 3, currency);
  }

  getFormattedRenewsPrice(currencyCode?: string): string {
    const rampIntervalCurrencies: Currency[] =
      this.rampIntervals[1]?.currencies;

    if (!rampIntervalCurrencies) {
      return formatPrice(this.amount, this.currency);
    }

    const { currency, unit_amount } = getCurrencyByCurrencyCode(
      rampIntervalCurrencies,
      currencyCode,
    );

    return formatPrice(unit_amount, currency);
  }

  getAmountPerWeek(currentAmount?: number) {
    const amount: number = currentAmount || this.amount;

    let amountPerWeek = 0.0;
    switch (this.intervalUnit) {
      case 'days':
        amountPerWeek = (amount / this.intervalLength) * 7;
        break;
      case 'months':
        amountPerWeek = amount / this.intervalLength / 4;
        break;
    }
    amountPerWeek = Math.floor(amountPerWeek * 100) / 100;
    return amountPerWeek;
  }

  getAmountPerDay(currentAmount?: number) {
    const amount: number = currentAmount || this.amount;

    let amountPerDay = 0.0;
    switch (this.intervalUnit) {
      case 'days':
        amountPerDay = amount / this.intervalLength;
        break;
      case 'months':
        amountPerDay = amount / this.intervalLength / 30;
        break;
    }

    amountPerDay = Math.floor(amountPerDay * 100) / 100;
    return amountPerDay;
  }

  getFirstRampIntervalAmountPerDay(currencyCode?: string): number {
    const { unit_amount } = getCurrencyByCurrencyCode(
      this.rampIntervals?.[1]?.currencies ?? this.currencies,
      currencyCode || 'USD',
    );

    return this.getAmountPerDay(unit_amount);
  }

  getFormattedDailyPrice(currencyCode?: string): string {
    if (!currencyCode || !this.currencies) {
      const amountPerDay = this.getAmountPerDay(this.amount);
      return formatPrice(amountPerDay, this.currency);
    }

    const { unit_amount, currency } = getCurrencyByCurrencyCode(
      this.currencies,
      currencyCode,
    );
    const amountPerDay = this.getAmountPerDay(unit_amount);
    return formatPrice(amountPerDay, currency);
  }

  getFormattedWeeklyPrice(currencyCode?: string): string {
    if (!currencyCode || !this.currencies) {
      const amountPerWeek = this.getAmountPerWeek(this.amount);
      return formatPrice(amountPerWeek, this.currency);
    }

    const { unit_amount, currency } = getCurrencyByCurrencyCode(
      this.currencies,
      currencyCode,
    );
    const amountPerWeek = this.getAmountPerWeek(unit_amount);
    return formatPrice(amountPerWeek, currency);
  }

  getFormattedInvoicePrice(
    currencyCode?: string,
    minFractions = 2,
    maxFractions = 2,
  ): string {
    if (!currencyCode || !this.currencies) {
      return formatPrice(
        this.setupFee,
        this.currency,
        minFractions,
        maxFractions,
      );
    }

    const { setup_fee: setupFee, currency } = getCurrencyByCurrencyCode(
      this.currencies,
      currencyCode,
    );
    return formatPrice(setupFee, currency, minFractions, maxFractions);
  }

  getTrialEndFormattedDate(date?: Date): string {
    const currentDate = date || this.getTrialEndDate();
    const language = getCurrentLanguage();

    const timeFormatted = Intl.DateTimeFormat(language, {
      hour: 'numeric',
      minute: 'numeric',
    }).format(currentDate);
    const dateFormatted = Intl.DateTimeFormat(language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(currentDate);
    return `${timeFormatted}, ${dateFormatted}`;
  }

  getTrialFormattedDateWithWeekDay(date: Date): string {
    const language = getCurrentLanguage();

    const dateFormatted = Intl.DateTimeFormat(language, {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    }).format(date);
    return dateFormatted;
  }

  getTrialAfterFiveDaysDate(date?: Date): Date {
    const currentDate = date || new Date();

    return new Date(currentDate.setDate(currentDate.getDate() + 5));
  }

  getTrialEndDate(date?: Date): Date {
    const currentDate = date || new Date();

    switch (this.trialUnit) {
      case 'days':
        return new Date(
          currentDate.setDate(currentDate.getDate() + this.trialLength),
        );
      case 'months':
        return new Date(
          currentDate.setMonth(currentDate.getMonth() + this.trialLength),
        );
    }
  }

  getTerm(t?: TFunction, isMonthFormat?: boolean) {
    const interval = this.intervalLength;
    const unit = this.intervalUnit;

    if (
      (interval === 3 && unit === 'months') ||
      (isMonthFormat && interval === 1)
    ) {
      return isMonthFormat
        ? `${
            t
              ? t('month_with_duration', { duration: interval })
              : `${interval}-Month`
          }`
        : `${t ? t('weeks', { duration: 12 }) : '12 weeks'}`;
    } else if (interval === 12 && unit === 'months') {
      return t ? `1-${t('year')}` : '1-Year';
    } else if (interval === 1) {
      return t ? t(unit.slice(0, -1)) : unit.slice(0, -1);
    } else if (interval === 7 && unit === 'days') {
      return t
        ? `${interval}-${t(unit.slice(0, -1))}`
        : `${interval}-${unit.slice(0, -1)}`;
    } else {
      return `${interval} ${t ? t(unit) : unit}`;
    }
  }

  getRenewPeriodName(t?: TFunction) {
    const interval = this.intervalLength;
    const unit = this.intervalUnit;

    if (interval === 3 && unit === 'months') {
      return t ? t('quarter') : 'quarter';
    } else if (interval === 12 && unit === 'months') {
      return t ? t('annual') : 'annual';
    } else if (interval === 1) {
      return t ? t(unit.slice(0, -1)) : unit.slice(0, -1);
    } else {
      return `${interval} ${t ? t(unit) : unit}`;
    }
  }

  getPlanSectionTerm(t?: TFunction, isMonthFormat?: boolean) {
    const interval = this.intervalLength;
    const unit = this.intervalUnit;

    if (
      (interval === 3 && unit === 'months') ||
      (interval === 1 && unit === 'months')
    ) {
      return isMonthFormat
        ? `${
            t
              ? t('month_with_duration', { duration: interval })
              : `${interval}-Month`
          }`
        : `${
            t
              ? t('weeks_with_duration', { duration: interval * 4 })
              : '12 weeks'
          }`;
    } else if (interval === 12 && unit === 'months') {
      return t ? `1-${t('year')}` : '1-Year';
    } else if (interval === 1) {
      return t ? t(unit.slice(0, -1)) : unit.slice(0, -1);
    } else if (interval === 7 && unit === 'days') {
      return t
        ? `${interval}-${t(unit.slice(0, -1))}`
        : `${interval}-${unit.slice(0, -1)}`;
    } else {
      return `${interval} ${t ? t(unit) : unit}`;
    }
  }

  getRenewPeriodAdverbName(t?: TFunction) {
    const periodNameFormat =
      this.intervalUnit === 'months' ? 'monthly' : 'daily';

    if (this.intervalLength === 3 && this.intervalUnit === 'months') {
      return t ? t('quarterly') : 'quarterly';
    } else if (this.intervalLength === 12 && this.intervalUnit === 'months') {
      return t ? t('annually') : 'annually';
    } else if (this.intervalLength === 1) {
      return t ? t(periodNameFormat) : periodNameFormat;
    } else {
      return `${this.intervalLength} ${
        t ? t(periodNameFormat) : periodNameFormat
      }`;
    }
  }

  getPriceWithTrial(currencyCode?: string) {
    if (!currencyCode || !this.currencies) {
      return formatPrice((this.setupFee || 0) + this.amount, this.currency);
    }

    const {
      unit_amount,
      setup_fee: setupFee,
      currency,
    } = getCurrencyByCurrencyCode(this.currencies, currencyCode);
    return formatPrice(unit_amount + setupFee, currency);
  }

  getPriceWithoutTrial(currencyCode?: string) {
    if (!currencyCode || !this.currencies) {
      return formatPrice(this.amount - (this.setupFee || 0), this.currency);
    }

    const {
      unit_amount,
      setup_fee: setupFee,
      currency,
    } = getCurrencyByCurrencyCode(this.currencies, currencyCode);
    return formatPrice(unit_amount - setupFee, currency);
  }

  getPriceWithTotalSavings(discountPercent: number, currencyCode?: string) {
    const discountValue = this.getDiscountValue(discountPercent, currencyCode);
    const totalSavings = discountValue + (this.setupFee || 0);

    if (!currencyCode || !this.currencies) {
      return formatPrice(this.amount - totalSavings, this.currency);
    }

    const { unit_amount, currency } = getCurrencyByCurrencyCode(
      this.currencies,
      currencyCode,
    );

    return formatPrice(unit_amount - totalSavings, currency);
  }

  getPriceWithoutDiscount = (
    discountPercent: number,
    currencyCode?: string,
  ): string => {
    const originalPrice = (this.amount / (1 - discountPercent / 100)).toFixed(
      2,
    );

    if (!currencyCode || !this.currencies) {
      return formatPrice(originalPrice, this.currency);
    }

    const { currency } = getCurrencyByCurrencyCode(
      this.currencies,
      currencyCode,
    );

    return formatPrice(originalPrice, currency);
  };

  getTotalSavings = (
    discountPercent: number,
    currencyCode?: string,
  ): string => {
    const discountValue = this.getDiscountValue(discountPercent, currencyCode);
    if (!currencyCode || !this.currencies) {
      const totalSavings = discountValue + (this.setupFee || 0);
      return formatPrice(totalSavings, this.currency);
    }

    const { currency, setup_fee: setupFee } = getCurrencyByCurrencyCode(
      this.currencies,
      currencyCode,
    );

    const totalSavings = discountValue + (setupFee || 0);
    return formatPrice(totalSavings, currency);
  };

  getDiscountValue = (
    discountPercent: number,
    currencyCode?: string,
  ): number => {
    if (!currencyCode || !this.currencies) {
      const discountValue = (this.amount * discountPercent) / 100;
      return Math.floor(discountValue * 100) / 100;
    }

    const { unit_amount } = getCurrencyByCurrencyCode(
      this.currencies,
      currencyCode,
    );
    const discountValue = (unit_amount * discountPercent) / 100;
    return Math.floor(discountValue * 100) / 100;
  };

  getDiscountAmount = (
    discountPercent: number,
    currencyCode?: string,
  ): string => {
    const discountValue = this.getDiscountValue(discountPercent, currencyCode);
    if (!currencyCode || !this.currencies) {
      return formatPrice(discountValue, this.currency);
    }

    const { currency } = getCurrencyByCurrencyCode(
      this.currencies,
      currencyCode,
    );
    return formatPrice(discountValue, currency);
  };
}
