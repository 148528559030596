import firebase from 'firebase/compat/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import {
  getRemoteConfig,
  fetchAndActivate,
  getAll,
} from 'firebase/remote-config';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const remoteConfigDefaults = {
  default_extended_product_code: 'co-loona-web-ar-q-t7-59',
  default_standard_product_code: 'co-loona-web-ar-q-t14-59',
  promo_extended_product_code: 'year-extend-promo-2w-trial',
  promo_standard_product_code: 'year-promo-1w-trial',
  skip_trial_default_product_code: 'co-loona-web-ar-q-t7-1-59',
  promo_skip_trial_no_trial_product_code: 'co-loona-web-ar-q-t0-48-st',
  promo_skip_trial_no_trial_product_code_plan_select: 'co-loona-web-ar-m-t0-23',
  premium_invite_product_code: 'co-loona-web-ar-y-t30-39',
  default_product_code: 'co-loona-web-ar-q-t7-59-pt0099',
  plan_select_month_direct_product_code: 'co-loona-web-ar-m-t0-29-r19',
  plan_select_month_upsell_step1_product_code: 'co-loona-web-ar-q-t0-44',
  plan_select_month_upsell_step2_product_code: 'co-loona-web-ar-q-t0-39',
  plan_select_quarter_direct_product_code: 'co-loona-web-ar-q-t0-59-r29',
  plan_select_week_direct_product_code: 'co-loona-web-ar-wm-t0-29-5',
  plan_select_week_upsell_step1_product_code: 'co-loona-web-ar-q-t0-55',
  plan_select_week_upsell_step2_product_code: 'co-loona-web-ar-q-t0-47',
  workbook_addon_product_code: 'co-loona-web-nc-workbook',
  workbook_addon_with_discount_product_code: 'co-loona-web-nc-workbook-dsc',
  web_audio_product_code: 'co-loona-web-nc-workbook-dac',
};

export let firebaseId: string | undefined = undefined;

export const firebaseApp = firebase.initializeApp(config);

const auth = getAuth(firebaseApp);
signInAnonymously(auth).then((userCredential) => {
  firebaseId = userCredential.user?.uid;
});

export const remoteConfig = getRemoteConfig(firebaseApp);

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = remoteConfigDefaults;
fetchAndActivate(remoteConfig).then((_) => {
  getAll(remoteConfig);
});
