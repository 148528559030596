import { Reducer } from 'redux';
import { SET_AUTH_TOKEN } from './actions/register';
import { UserActionTypes } from './actions/userActions.types';
import {
  SET_COUNTRY_CODE,
  SET_COUNTRY_CURRENCY,
  SET_PAYMENT_METHOD,
  SET_PLAN_CODE,
  SET_RESTRICT_MAILING,
  SET_SUBSCRIPTION_EXPIRED_DATE,
  SET_SUBSCRIPTION_STATUS,
  SET_SUBSCRIPTION_TOKEN,
} from './actions/setUserInfo';
import { UserState } from './state';
import { SET_SUBSCRIPTION } from './actions/setSubscriptionActive';
import {
  SET_TRIAL_OFFER_DISCOUNT_APPLIED,
  SET_TRIAL_OFFER_SHOWN,
} from './actions/setTrialOfferInfo';
import { SET_PREVENT_BACK_PAYMENT_OFFER_CONFIRMED } from './actions/setPreventPaymentBack';
import { SET_PLAN_SELECT_TYPE } from './actions/setPlanType';
import { SET_BOUGHT_PRODUCT } from './actions/setProductBought';

export const INITIAL_STATE: UserState = {
  authToken: undefined,
  hasSubscription: undefined,
  oldUser: undefined,
  email: undefined,
  isRestrictMailing: undefined,
  planCode: undefined,
  countryCode: undefined,
  currency: undefined,
  isTrialOfferShown: undefined,
  isPreventBackPaymentOfferConfirmed: undefined,
  isTrialOfferDiscountApplied: undefined,
  subscriptionToken: undefined,
  subscriptonExpiredDate: undefined,
  subscriptionStatus: undefined,
  subscriptionEventStatus: undefined,
  paymentMethod: undefined,
  planType: undefined,
  boughtProduct: undefined,
};

export const userReducer: Reducer<UserState, UserActionTypes> = (
  state = INITIAL_STATE,
  action: UserActionTypes,
) => {
  switch (action.type) {
    case SET_SUBSCRIPTION: {
      return {
        ...state,
        hasSubscription: action.payload.hasSubscription,
        oldUser: action.payload.oldUser,
      };
    }

    case SET_AUTH_TOKEN: {
      return {
        ...state,
        authToken: action.payload.authToken,
        email: action.payload.email,
      };
    }

    case SET_RESTRICT_MAILING: {
      return {
        ...state,
        isRestrictMailing: action.payload.isRestrictMailing,
      };
    }

    case SET_PLAN_CODE: {
      return {
        ...state,
        planCode: action.payload.planCode,
      };
    }

    case SET_COUNTRY_CODE: {
      return {
        ...state,
        countryCode: action.payload.countryCode,
      };
    }

    case SET_COUNTRY_CURRENCY: {
      return {
        ...state,
        currency: action.payload.currency,
      };
    }

    case SET_TRIAL_OFFER_SHOWN: {
      return {
        ...state,
        isTrialOfferShown: action.payload.isTrialOfferShown,
      };
    }

    case SET_TRIAL_OFFER_DISCOUNT_APPLIED: {
      return {
        ...state,
        isTrialOfferDiscountApplied: action.payload.isTrialOfferDiscountApplied,
      };
    }

    case SET_SUBSCRIPTION_EXPIRED_DATE: {
      return {
        ...state,
        subscriptonExpiredDate: action.payload.subscriptonExpiredDate,
      };
    }

    case SET_SUBSCRIPTION_TOKEN: {
      return {
        ...state,
        subscriptionToken: action.payload.subscriptionToken,
      };
    }

    case SET_SUBSCRIPTION_STATUS: {
      return {
        ...state,
        subscriptionStatus: action.payload.subscriptionStatus,
        subscriptionEventStatus: action.payload.subscriptionEventStatus,
      };
    }

    case SET_PREVENT_BACK_PAYMENT_OFFER_CONFIRMED: {
      return {
        ...state,
        isPreventBackPaymentOfferConfirmed:
          action.payload.isPreventBackPaymentOfferConfirmed,
      };
    }

    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: action.payload.paymentMethod,
      };
    }

    case SET_PLAN_SELECT_TYPE: {
      return {
        ...state,
        planType: action.payload.planType,
      };
    }

    case SET_BOUGHT_PRODUCT: {
      return {
        ...state,
        boughtProduct: action.payload.boughtProduct,
      };
    }

    default:
      return state;
  }
};
