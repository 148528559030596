import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';
import Spacer from 'components/spacer/Spacer';
import Divider from 'components/divider/Divider';
import { GraphsContainer } from 'subscription/upsell/graphsContainer/GraphsContainer';
import { DiscountPopup } from 'subscription/upsell/discount/DiscountPopup';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { PlanSelectType } from 'subscription/singleproduct/components/plansection/PlanSection.constants';
import {
  getMonthUpsellStepOneProductCode,
  getMonthUpsellStepTwoProductCode,
  getWeekUpsellStepOneProductCode,
  getWeekUpsellStepTwoProductCode,
} from 'bootstrap/data/product/Product.utils';
import { withDefaultProduct } from 'subscription/withDefaultProduct/withDefaultProduct';
import { useTranslation } from 'react-i18next';
import { formatPrice, pushWithSearchQuery } from 'utils';
import { changeSubscriptionPlan } from 'bootstrap/user/actions';
import { getErrorMessage } from 'bootstrap/net/commonnet';
import { ErrorPopup } from 'components/errorpopup/ErrorPopup';
import BgImage from 'assets/bg_particles_yellow.png';
import { appAnalytics } from 'analytics';
import { ScreenOpened } from 'analytics/AllEvents';
import { useLocation } from 'react-router-dom';
import { SubscriptionSwitchSuccess } from 'cancelsubscription/analytics';
import styles from './styles.module.scss';

const DISCOUNT = 25;

export const MonthWeekUpsellFlow: React.FC = withDefaultProduct(() => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const location = useLocation();

  const { products } = useAppSelector((state) => state.product);
  const { planType, boughtProduct } = useAppSelector((state) => state.user);

  const [isDiscountPopupShown, setIsDiscountPopupShown] =
    useState<boolean>(false);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDiscountApplied, setDiscountApplied] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    appAnalytics.trackEvent(
      new ScreenOpened(`${location.pathname}`, { planType }),
    );
  }, []);

  const productCode = useMemo(() => {
    if (isDiscountApplied) {
      return planType === PlanSelectType.MONTH
        ? getMonthUpsellStepTwoProductCode()
        : getWeekUpsellStepTwoProductCode();
    }
    return planType === PlanSelectType.MONTH
      ? getMonthUpsellStepOneProductCode()
      : getWeekUpsellStepOneProductCode();
  }, [isDiscountApplied]);

  const product = useMemo(() => products.get(productCode), [productCode]);

  // @ts-ignore
  const convertedBoughtProduct = products.get(boughtProduct?.code);

  const totalSavingsAmount =
    (convertedBoughtProduct?.amount || 0) * 3 -
    (product?.amount || 0) -
    (convertedBoughtProduct?.setupFee || 0);

  const totalSavings = formatPrice(totalSavingsAmount, product?.currency);

  return (
    <>
      <ErrorPopup
        visible={!!error}
        error={error}
        onClose={() => setError('')}
      />
      {isDiscountPopupShown && (
        <DiscountPopup
          product={product}
          discount={DISCOUNT}
          handleContinueClick={() => {
            setDiscountApplied(true);
            setIsDiscountPopupShown(false);
          }}
        />
      )}
      <div className={`${styles.container} maxWidth`}>
        <div className={styles.content}>
          <div className={styles.title}>
            Boost Your <span className={styles.accent}>Wellness Journey</span>{' '}
            <br /> with a {product?.getTerm(t, true)} Plan!
          </div>
          {isDiscountApplied && (
            <div className={`${styles.cardContainer} ${styles.giftCard}`}>
              <img src={BgImage} className={styles.particles} alt="" />
              <div className={styles.label}>
                Additional {DISCOUNT}% <br /> discount applied!
              </div>
            </div>
          )}
          <div className={styles.description}>
            Most Loóna users continue using the app after reaching their goal of
            improving their sleep and reducing anxiety with personalized
            guidance and support
          </div>
          <p>
            You can upgrade to maintain your mental wellness and save money,
            with our {product?.getTerm(t, true)} subscription
          </p>
        </div>

        <GraphsContainer
          boughtProduct={convertedBoughtProduct}
          upsellProduct={product}
        />

        <div className={styles.planContent}>
          <div className={styles.title}>Price breakdown:</div>
          <Divider isDividerWithNoMargin />

          <div className={`${styles.field} ${styles.plan}`}>
            <div className={styles.label}>
              {product?.getTerm(t, true)?.toLowerCase()} with your plan
            </div>
            <span>
              {convertedBoughtProduct?.getTreeMonthsFormattedPrice('USD')}
            </span>
          </div>

          <div className={`${styles.field} ${styles.plan} ${styles.red}`}>
            <div className={styles.label}>Discount for today</div>
            <span>{totalSavings}</span>
          </div>
          <div className={`${styles.field} ${styles.plan}`}>
            <div className={styles.label}>Credit from previous payment</div>
            <span>{convertedBoughtProduct?.getFormattedInvoicePrice()}</span>
          </div>
          <Divider isDividerWithNoMargin />
          <div className={`${styles.field} ${styles.total}`}>
            <div className={styles.label}>Total Today:</div>
            <span>{product?.getFormattedPrice()}</span>
          </div>
        </div>
        <Spacer />
        <div className={styles.buttonsContainer}>
          <LoadingButton
            className={styles.loadingButton}
            isLoading={isLoading}
            label={`Buy and save ${totalSavings}`}
            onClick={() => {
              setIsDiscountPopupShown(false);
              setLoading(true);

              dispatch(changeSubscriptionPlan(productCode))
                .then(() => {
                  appAnalytics.trackEvent(
                    new SubscriptionSwitchSuccess(productCode),
                  );
                  dispatch(pushWithSearchQuery('/done'));
                  setLoading(false);
                })
                .catch((error) => {
                  setError(getErrorMessage(error));
                  setLoading(false);
                });
            }}
          />

          <button
            className={`${styles.button} ${styles.secondary}`}
            onClick={() => {
              if (isDiscountApplied) {
                dispatch(pushWithSearchQuery('/done'));
              } else {
                setIsDiscountPopupShown(true);
              }
            }}
          >
            Continue with current plan
          </button>
        </div>
      </div>
    </>
  );
});
