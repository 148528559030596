import React, { useEffect } from 'react';
import Modal from 'components/modal/Modal';
import { ViewSkipBasicUpsellPopup } from 'analytics/AllEvents';
import { appAnalytics } from 'analytics';
import { useTranslation } from 'react-i18next';
import WorkbookOfferImage from 'assets/onboarding/workbook_offer.png';
import styles from './styles.module.scss';

type Props = {
  discountPercent?: number;
  handleContinueClick: () => void;
};

export const DiscountPopup = ({
  discountPercent,
  handleContinueClick,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    appAnalytics.trackEvent(new ViewSkipBasicUpsellPopup());
  }, []);

  return (
    <Modal visible={true} className={styles.modal}>
      <img
        src={WorkbookOfferImage}
        className={styles.image}
        alt="workbook offer"
      />
      <div className={styles.contentWrapper}>
        <div className={styles.title}>
          Your success is <br /> our #1 priority
        </div>
        <div className={styles.description}>
          Gran your Premium workbook now for <b>{discountPercent}% off</b>, a
          special offer just for you!
        </div>
        <button onClick={handleContinueClick}>{t('Claim now')}</button>
      </div>
    </Modal>
  );
};
