import { appAnalytics } from 'analytics';
import { SubscriptionError, SubscriptionSuccess } from 'analytics/AllEvents';
import { OptionalParams } from 'analytics/Event';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { goToNextPage, setSubscriptionActive } from 'bootstrap/user/actions';
import { getQueryVariable, pushWithSearchQuery } from 'utils';
import { SuccessPaymentProps } from './Recurly.types';
import { useFeatures } from 'useFeatures';
import { setProductBought } from 'bootstrap/user/actions/setProductBought';

export const useRecurlyLogic = () => {
  const dispatch = useAppDispatch();
  const idx = getQueryVariable('idx');

  const {
    isGadTest,
    isAdhdTest,
    isIgnoreSkipTrialFlow,
    isWorkbookUpsellFlow,
    upsellFlow,
  } = useFeatures();

  const { email, currency } = useAppSelector((state) => state.user);

  const onPaymentSuccess = ({
    orderId,
    productBought,
    zip,
    firstName,
    lastName,
  }: SuccessPaymentProps) => {
    dispatch(setSubscriptionActive(false));
    dispatch(setProductBought(productBought));

    const params: OptionalParams = {
      eventId: orderId,
      email: email,
      zip: zip,
      firstName: firstName,
      lastName: lastName,
      onboardingType: 'onboarding',
    };

    appAnalytics.trackEvent(
      new SubscriptionSuccess(
        productBought.trialLength
          ? productBought.setupFee
          : productBought.amount,
        currency || productBought.currency,
        productBought.code,
        params,
      ),
    );

    if (idx) {
      dispatch(pushWithSearchQuery('/quick-purchase/done'));
    }

    if (upsellFlow) {
      dispatch(pushWithSearchQuery('/basic-upsell'));
    } else if (isWorkbookUpsellFlow || isAdhdTest || upsellFlow) {
      dispatch(pushWithSearchQuery('/workbook'));
    } else if (isIgnoreSkipTrialFlow) {
      dispatch(pushWithSearchQuery('/done'));
    } else {
      dispatch(goToNextPage(isGadTest));
    }
  };

  const onPaymentError = (message: string) => {
    appAnalytics.trackEvent(new SubscriptionError(message));
  };

  return {
    currency,
    onPaymentSuccess,
    onPaymentError,
  };
};
