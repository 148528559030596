import React, { useState } from 'react';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import Spacer from 'components/spacer/Spacer';
import { EbookOfferBox } from 'subscription/workbook/components/ebookoffer/EbookOfferBox';
import { useAddonsLogic } from 'subscription/workbook/Addons.hook';
import { ErrorPopup } from 'components/errorpopup/ErrorPopup';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';
import { DiscountPopup } from 'subscription/workbook/components/discountPopup/DiscountPopup';
import { useAppDispatch } from 'bootstrap/hooks';
import { pushWithSearchQuery } from 'utils';
import { PlanSelectType } from 'subscription/singleproduct/components/plansection/PlanSection.constants';
import {
  convertToProduct,
  getAddonsPlansConfig,
  getWorkbookProductCode,
  getWorkbookWithDiscountProductCode,
} from 'bootstrap/data/product/Product.utils';
import { withDefaultProduct } from 'subscription/withDefaultProduct/withDefaultProduct';
import { ServerProduct } from 'bootstrap/data/product/Product.types';
import BgImage from 'assets/bg_particles_yellow.png';
import styles from './styles.module.scss';

const DISCOUNT_STEP_ONE = 50;
const DISCOUNT_STEP_TWO = 75;

export const BasicUpsellFlow: React.FC = withDefaultProduct(() => {
  const dispatch = useAppDispatch();

  const [isDiscountApplied, setDiscountApplied] = useState<boolean>(false);
  const [isDiscountPopupShown, setDiscountPopupShown] = useState(false);

  const workbookProductCode = getWorkbookProductCode();
  const workbookWithDiscountProductCode = getWorkbookWithDiscountProductCode();

  const { isLoading, error, planType, setError, onAddonBuyClickHandler } =
    useAddonsLogic({
      isDiscountApplied: isDiscountApplied,
      planCode: isDiscountApplied
        ? workbookWithDiscountProductCode
        : workbookProductCode,
    });

  const addonsPlans = getAddonsPlansConfig();

  const workbookProduct: ServerProduct =
    // @ts-ignore
    addonsPlans?.items?.[workbookProductCode];
  const convertedWorkbookProduct = convertToProduct(workbookProduct);

  const workbookWithDiscountProduct: ServerProduct =
    // @ts-ignore
    addonsPlans?.items?.[workbookWithDiscountProductCode];
  const convertedWorkbookWithDiscountProduct = convertToProduct(
    workbookWithDiscountProduct,
  );

  return (
    <>
      <ErrorPopup
        visible={!!error}
        error={error}
        onClose={() => setError('')}
      />

      {isDiscountPopupShown && (
        <DiscountPopup
          discountPercent={25}
          handleContinueClick={() => {
            setDiscountApplied(true);
            setDiscountPopupShown(false);
          }}
        />
      )}
      <div className={`${styles.container} maxWidth`}>
        <h4>
          Fast-Track Your Progress <br /> with
          <span className={styles.accent}> Premium Workbook</span>
        </h4>
        {isDiscountApplied && (
          <div className={`${styles.cardContainer} ${styles.giftCard}`}>
            <img src={BgImage} className={styles.particles} alt="" />
            <div className={styles.label}>
              Additional 25% <br /> discount applied!
            </div>
          </div>
        )}
        <div className={styles.description}>
          <span>
            Recommended for new users like you to help improve your sleep and
            reduce anxiety.
          </span>
          <span>
            With Workbook you are 29% more likely to achieve better results in
            first 3 weeks.
          </span>
        </div>
        <EbookOfferBox
          items={[
            'Tailored relaxation techniques',
            'Personalized action plans',
            'Foods and Activities recommendations',
          ]}
        />
        <Spacer />
        <div className={styles.totalWrapper}>
          <h2 className={styles.total}>Total today</h2>
          <div className={styles.priceWrapper}>
            <p className={styles.discount} aria-label="Discount">
              {`-${isDiscountApplied ? DISCOUNT_STEP_TWO : DISCOUNT_STEP_ONE}%`}
            </p>
            <p className={styles.oldPrice} aria-label="Original price">
              {convertedWorkbookProduct.getPriceWithoutDiscount(
                DISCOUNT_STEP_ONE,
              )}
            </p>
            <p className={styles.newPrice} aria-label="Discounted price">
              {isDiscountApplied
                ? convertedWorkbookWithDiscountProduct.getFormattedPrice()
                : convertedWorkbookProduct.getFormattedPrice()}
            </p>
          </div>
        </div>
        <StickyContainer className={styles.stickyContainer}>
          <button
            className={styles.grayButton}
            onClick={() => {
              if (
                isDiscountApplied &&
                (planType === PlanSelectType.MONTH ||
                  planType === PlanSelectType.WEEK)
              ) {
                dispatch(pushWithSearchQuery('/weekmonth-upsell'));
              } else if (
                isDiscountApplied &&
                planType === PlanSelectType.QUARTER
              ) {
                dispatch(pushWithSearchQuery('/quarter-upsell'));
              } else {
                setDiscountPopupShown(true);
              }
            }}
          >
            Skip
          </button>
          <LoadingButton
            isLoading={isLoading}
            label="Add to my plan"
            className={`${styles.button} ${styles.greenButton}`}
            onClick={onAddonBuyClickHandler}
          />
        </StickyContainer>
        <div className={styles.note}>
          On click, your account will be charged immediately for the add-on
          services or the products selected.
        </div>
      </div>
    </>
  );
});
